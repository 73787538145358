import "swiper/swiper.min.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Schedule = (props) => {
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const filteredNews = news.filter(
    (x) => x.StationId === Number(3) || x.StationId === Number(0)
  );

  const filteredOAPs = oap.filter((x) => x.StationId === Number(3));
  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(3))
    .slice(0, 6);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms, OAP]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }
  return (
    <ErrorBoundary>
      <div id="content">
        <div className="bg1 p93">
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block"></div>
            </div>
          </div>
        </div>

        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block">
                <h3 className="col2">
                  Captivating moments on ROSE FM <br />
                  <span className="col1">radio station!</span>
                </h3>
                <h4>The station that brings you intriguing programs</h4>
                <a href="https://live.enyinba.com" target="_blank">
                  <i className="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*TOPS NEWS START*/}
        {filteredNews && Object.keys(filteredNews).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>TOP NEWS</h3>
              {filteredNews.map((item, index) => {
                return (
                  <>
                    <Link to={`/news/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}
                          <div
                            style={{
                              fontSize: "18px",
                              color: "white",
                              fontStyle: "italic",
                              height: "25px",
                              width: "300px",
                              alignContent: "center",
                              marginRight: "5px",
                              backgroundColor: "#8f2325",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {formatStringToDate(item.CreatedDate)}
                          </div>
                          {/*</div>*/}
                          <div
                            style={{
                              fontSize: "20px",
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>

                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "17px",
                              color: "black",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ParagraphOne.split(" ").slice(0, 5).join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*TOPS NEWS END*/}

        {/*ADVERT START*/}
        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              {isMobile ? (
                <div className="info_block">
                  <h3
                    className="col2"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ROSE FM website? <Link to="/contact">click here</Link>{" "}
                    <br />
                  </h3>
                  <h4
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    The station that brings you intriguing programs
                  </h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              ) : (
                <div className="info_block">
                  <h3 className="col2">
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ROSE FM website? <Link to="/contact">click here</Link>{" "}
                    <br />
                  </h3>
                  <h4>The station that brings you intriguing programs</h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*ADVERT END*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
