import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import axios from "axios";
import { serverLink } from "../../../Resources/Url";
import { showAlert } from "../Sweetalert/sweetalert";
import HomeContentLoader from "../Loaders/HomeContentLoader/HomeContentLoader";
import FormSubmissionLoader from "../Loaders/FormSubmissionLoader/FormSubmissionLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";

const Footer = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const [createItem, setCreateItem] = useState({
    NewsLetterId: "",
    EmailAddress: "",
    Date: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      NewsLetterId: "",
      EmailAddress: "",
      Date: "",
    });
  };

  const onEdit = (e) => {
    setCreateItem({
      ...createItem,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmittingForm = async () => {
    setIsSubmittingForm(true);

    if (createItem.EmailAddress === "") {
      setTimeout(async () => {
        setIsSubmittingForm(false);
        await window.alert(`Please enter subject to your request.`);
      }, 1000);
      return false;
    }

    if (Object.keys(createItem).length > 0) {
      const data = {
        NewsLetterId: createItem.NewsLetterId,
        EmailAddress: createItem.EmailAddress,
        Date: `${currentMonth}/${currentDay}/${currentYear}`,
      };

      console.log("dada", data);

      axios
        .post(`${serverLink}shared/contact`, data)
        .then((res) => {
          if (res.data.message === "success") {
            setTimeout(async () => {
              setIsSubmittingForm(false);
              window.alert(
                `Request submitted successfully, our specialist will contact you soon!`
              );
              resetItem();
            }, 2000);
            return false;
          } else {
            setTimeout(async () => {
              setIsSubmittingForm(false);
              window.alert(
                `Sorry, form not submitted, check your internet connection and try!`
              );
            }, 2000);
            return false;
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  return (
    <ErrorBoundary>
      <div className="bg2 p42">
        <div className="container_12">
          <div className="sub_form">
            <div className="grid_5">
              <i className="fa fa-envelope-o"></i>
              <h4>Subscribe to our Newsletter</h4>
            </div>
            <form id="subscribe-form" className="clearfix">
              <div className="grid_5">
                <div className="success">
                  Your subscription request
                  <br /> has been sent!
                </div>
                <fieldset>
                  <label className="email">
                    <input
                      type="text"
                      name="EmailAddress"
                      id="EmailAddress"
                      required={true}
                      value={createItem.EmailAddress}
                      onChange={onEdit}
                      placeholder="Email Address"
                    />
                    <span className="error">
                      *This is not a valid email address.
                    </span>
                  </label>
                  &nbsp;
                </fieldset>
              </div>
              <div className="grid_2">
                <a
                  onClick={handleSubmittingForm}
                  data-type="submit"
                  className="link"
                >
                  submit
                </a>
              </div>
              {isSubmittingForm && <SubmittingLoader />}
            </form>
          </div>
        </div>
      </div>
      <footer id="footer" className="footer">
        <div className="container_12">
          <div className="grid_6">
            <span>
              <img src="images/logo_min.png" alt="" />
            </span>
            <div className="copy">
              <span>&copy;</span>
              <span id="copyright-year"></span>
              <span>|</span>
              <span>
                <a href="index-5.html">Privacy Policy</a>
              </span>{" "}
            </div>
          </div>
          <div className="grid_6">
            <ul className="social-links">
              <li>
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-rss"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-google-plus"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
