import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import logole from "../../../Resources/logole.png";
import logoeny from "../../../Resources/logoeny.png";
import logorose from "../../../Resources/logorose.png";
import facebook from "../../../Resources/facebook.png";
import loudSpeaker from "../../../Resources/loud_speaker.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const slideData = [
  // Replace these with your slide content (e.g., images, text, etc.)
  "Slide 1",
  "Slide 2",
  "Slide 3",
];

const Header = (props) => {
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );

  const [votes] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const [stations] = useState(
    typeof props.HomePageData.stations !== "undefined"
      ? props.HomePageData.stations
      : []
  );

  const [programs] = useState(
    typeof props.HomePageData.programs !== "undefined"
      ? props.HomePageData.programs
      : []
  );

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const filteredStations = stations.filter((r) => r.StationId === Number(3));

  const filteredPrograms = programs.filter((r) => r.StationId === Number(3));

  const navbarStyles = {
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    background: "#41444b",
    padding: "10px",
    marginTop: "30px", // Add marginTop to align with other items
  };

  const navStyles = {
    color: "black",
    listStyle: "none",
    // marginTop: "30px",
    display: isMobileMenuOpen ? "flex" : "none",
    flexDirection: "column",
    alignItems: "flex-start", // Align menu items to the left
    background: "white", // Gray background color for the mobile dropdown
    height: "auto", // Expand the dropdown height to fit all items
    position: "absolute",
    width: "100%",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
  };

  const listItemStyles = {
    color: "black",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    margin: "10px 0",
    padding: "5px 10px", // Adjust the padding for spacing between items
  };

  const mobileMenuIconStyles = {
    color: "black",
    zIndex: 9999, // Ensure the dropdown is on top of other elements
    fontSize: "24px",
    cursor: "pointer",
  };

  const MarqueeSlideProgram = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slideData.length);
      }, 3000); // Change slide every 3 seconds

      return () => clearInterval(interval);
    }, []);

    return (
      <div
        style={{
          // position: "relative",
          width: "100%",
          height: "100vh",
          // overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "url('path/to/your/beautiful-background.jpg')", // Replace with your background image URL
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            backgroundColor: "#f0f0f0", // Replace with your desired background color
          }}
        >
          {slideData.map((slide, index) => (
            <div
              key={index}
              style={{
                // flexShrink: 0,
                padding: "10px 20px",
                fontSize: "24px",
                color: "#ffffff", // Replace with your desired font color
                backgroundColor: "#007bff", // Replace with your desired slide background color
                transition: "opacity 0.5s ease-in-out",
                opacity: index === currentSlide ? "1" : "0",
                animation: "marqueeAnimation 15s linear infinite",
              }}
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const marqueeCSS = `
@keyframes marqueeAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
`;

  return (
    <ErrorBoundary>
      <header
        id="header"
        style={{
          // backgroundColor: "#333333 ",
          backgroundColor: "rgb(231, 16, 16, 53%)",
        }}
      >
        <div className="container_12">
          {/*<div>*/}
          {/*  <a*/}
          {/*    href="https://facebook.com"*/}
          {/*    target="_blank"*/}
          {/*    style={{*/}
          {/*      color: "#ffff",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <img src={facebook} alt="" width="100px" />*/}
          {/*    /!*<p>Facebook</p>*!/*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="grid_12">
            <h1
              style={{
                marginBottom: "20px",
              }}
            >
              <Link to="/">
                <img src={logorose} alt="" width="220" />
              </Link>
            </h1>

            <div>
              <div
                // "
                // target="_blank"
                className="button"
              >
                <div>
                  <a
                    href="https://live.enyinba.com/3"
                    target="_blank"
                    style={{
                      color: "#ffff",
                    }}
                  >
                    <img src={loudSpeaker} alt="" />
                    <span>Live</span>
                  </a>
                </div>
                <br />
              </div>
            </div>
          </div>
          <div className="grid_12" style={{ backgroundColor: "#333333" }}>
            {isMobile ? (
              <>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                  <div className="container-fluid" style={navbarStyles}>
                    <a
                      className="navbar-brand"
                      href="#"
                      style={{ color: "white" }}
                    >
                      Menu
                    </a>
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={toggleMobileMenu}
                      style={mobileMenuIconStyles}
                    >
                      <i
                        className={`bi bi-${isMobileMenuOpen ? "x" : "list"}`}
                      ></i>
                    </button>
                  </div>
                  {isMobileMenuOpen && (
                    <div className="container-fluid" style={navStyles}>
                      <ul className="navbar-nav">
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/"
                            style={{
                              color: "black",
                            }}
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/oap"
                            style={{
                              color: "black",
                            }}
                          >
                            OAPs
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            onClick={closeMobileMenu}
                            className="nav-link"
                            to="/schedule"
                            style={{
                              color: "black",
                            }}
                          >
                            Schedule
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            className="nav-link"
                            to="/news"
                            onClick={closeMobileMenu}
                            style={{
                              color: "black",
                            }}
                          >
                            News
                          </Link>
                        </li>
                        <li className="nav-item" style={listItemStyles}>
                          <Link
                            className="nav-link"
                            to="/podcasts"
                            onClick={closeMobileMenu}
                            style={{
                              color: "black",
                            }}
                          >
                            Podcast
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </nav>
              </>
            ) : (
              <nav>
                <ul className="sf-menu">
                  <li
                    className={
                      firstItem && firstItem.includes("/") ? `current` : ""
                    }
                  >
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("oap") ? `current` : ""
                    }
                  >
                    <Link to="/oap">OAPs</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("schedule")
                        ? `current`
                        : ""
                    }
                  >
                    <Link to="/schedule">Schedule</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("news") ? `current` : ""
                    }
                  >
                    <Link to="/news">News</Link>
                  </li>
                  <li
                    className={
                      firstItem && firstItem.includes("podcasts")
                        ? `current`
                        : ""
                    }
                  >
                    <Link to="/podcasts">Podcast</Link>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>
      <div className="clear"></div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
