import "swiper/swiper.min.css";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../../Actions/Actions";
import icon1 from "../../Resources/page1_ico1.png";
import icon2 from "../../Resources/page1_ico2.png";
import icon3 from "../../Resources/page1_ico3.png";
import vote from "../../Resources/oap.jpg";
import arrayShuffle from "array-shuffle";
import ErrorBoundary from "../Shared/ErrorBoundary/ErrorBoundary";
import HomeContentLoader from "../Shared/Loaders/HomeContentLoader/HomeContentLoader";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Modal } from "react-st-modal";
import axios from "axios";
import { formatStringToDate, serverLink } from "../../Resources/Url";
import LoginLoader from "../Shared/LoginLoader/LoginLoader";

const Home = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [userIPAddress, setUserIPAddress] = useState("");
  const [currentProgram, setCurrentProgram] = useState(null);
  const [OAP, setOAP] = useState([]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  function formatDateToDDMMYYYY(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? arrayShuffle(props?.HomePageData.oap)
      : []
  );

  const [popupNotification] = useState(
    typeof props?.HomePageData.popupNotification !== "undefined"
      ? arrayShuffle(props?.HomePageData.popupNotification)
      : []
  );

  const [programs] = useState(
    typeof props?.HomePageData.programs !== "undefined"
      ? arrayShuffle(props?.HomePageData.programs)
      : []
  );

  const [news] = useState(
    typeof props.HomePageData.news !== "undefined"
      ? props.HomePageData.news
      : []
  );

  const filteredNews = news
    .filter((x) => x.StationId === Number(3) || x.StationId === Number(0))
    .slice(0, 6);

  const [podcast] = useState(
    typeof props.HomePageData.podcast !== "undefined"
      ? props.HomePageData.podcast
      : []
  );

  const filteredPodcast = podcast
    .filter((x) => x.StationId === Number(3) || x.StationId === Number(0))
    .slice(0, 6);

  const [pageReloaded, setPageReloaded] = useState(false);

  const [
    isSubmitPhoneNumberAndIppAddress,
    setIsSubmitPhoneNumberAndIppAddress,
  ] = useState(false);

  const haveOpted = JSON.parse(localStorage.getItem("haveOpted")) || [];

  const filteredOAPs = oap.filter((x) => x.StationId === Number(3));
  const filteredPopupNotification = popupNotification.filter(
    (x) => x.StationId === Number(3)
  );

  const filteredPrograms = programs
    .filter((x) => x.StationId === Number(3))
    .slice(0, 6);

  const updateNumberOfHits = () => {
    const sendData = {
      IpAddress: userIPAddress,
      StationId: 3,
      Date: `${currentMonth}/${currentDay}/${currentYear}`,
    };

    // Call the endpoint to insert the processed data into the table
    axios
      .post(`${serverLink}shared/hit`, sendData)
      .then((response) => {
        console.log("Data inserted successfully", response);
      })
      .catch((error) => {
        console.error("Error inserting data: ", error);
      });
  };

  useEffect(() => {
    if (!pageReloaded && haveOpted === 0) {
      setPageReloaded(true);
      window.location.reload();
    }
    if (haveOpted && Object.keys(haveOpted).length > 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [pageReloaded]);

  useEffect(() => {
    if (Object.keys(filteredPrograms).length > 0) {
      const checkProgramMatch = () => {
        const currentTime = new Date();

        const matchingProgram = programs.find((program) => {
          const startTime = new Date();
          const [startHour, startMinute] = program.StartTime.split(":");
          startTime.setHours(startHour, startMinute, 0, 0);

          const endTime = new Date();
          const [endHour, endMinute] = program.EndTime.split(":");
          endTime.setHours(endHour, endMinute, 0, 0);

          return currentTime >= startTime && currentTime <= endTime;
        });

        if (matchingProgram) {
          setCurrentProgram(matchingProgram);
        }
      };

      checkProgramMatch(); // Initial check on component mount

      const interval = setInterval(checkProgramMatch, 60000); // Check every minute

      return () => {
        clearInterval(interval);
      };
    }
    if (Object.keys(filteredPrograms).length < 1) {
      const matchingProgram = filteredPrograms.find(
        (r) => r.ProgramId === Number(1)
      );

      setCurrentProgram(matchingProgram);
    }
  }, []);

  const hasUpdateNumberOfHitsRun = useRef(false);

  function closeModalAfterGottenIP() {
    setIsOpen(false);
  }

  const onCancel = async () => {
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.reload();
      }, 2000);
    }, 1000);
  };

  function closeVotingSystem() {
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.reload();
      }, 2000);
    }, 1000);
  }

  useEffect(() => {
    const fetchUserIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const { ip } = response.data;
        setUserIPAddress(ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchUserIPAddress().then((r) => {});
  }, []);

  const handleVoteNow = async (e) => {
    localStorage.removeItem("haveOpted");
    setIsSubmitPhoneNumberAndIppAddress(true);
    const newItem = {
      IpAddress: userIPAddress,
      StationId: 3,
    };

    setTimeout(async () => {
      localStorage.setItem("haveOpted", JSON.stringify(newItem));

      setTimeout(async () => {
        setIsSubmitPhoneNumberAndIppAddress(false);
        closeModalAfterGottenIP();
        window.location.href = "https://www.vote.enyinba.com";
      }, 2000);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(async () => {
      setOAP(filteredOAPs);
    }, 1000);
  }, [filteredOAPs, filteredPrograms]);

  const onAttemptClose = () => {
    window.alert(`Please click cancel or vote now!`);
  };

  useEffect(() => {
    if (!hasUpdateNumberOfHitsRun.current) {
      updateNumberOfHits();
      hasUpdateNumberOfHitsRun.current = true;
    }
  }, [haveOpted]);

  if (Object.keys(OAP).length === 0) {
    return <HomeContentLoader />;
  }

  return (
    <ErrorBoundary>
      <div id="content">
        <div className="container_12 p100">
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon1} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                FAMILY & EDUCATION ORIENTATION PROGRAMS
              </h3>
              <h4></h4>
              <p>
                The station focuses on providing content that is suitable for
                the whole family, promoting family values and relationships.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4 wrap">
            <div className="thumb">
              <div className="circle">
                <img src={icon2} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                RELIGIOUS CONTENT
              </h3>
              <h4></h4>
              <p>
                The station offers programs related to religion, likely
                including religious discussions, teachings, and spiritual
                content
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
          <div className="grid_4">
            <div className="thumb">
              <div className="circle">
                <img src={icon3} alt="" />
              </div>
              <h3
                style={{
                  fontFamily: "Open Sans, sans-serif",
                  fontWeight: "300",
                  textTransform: "uppercase",
                  display: "block",
                  fontSize: "35px",
                  lineHeight: "30px",
                }}
              >
                LOCAL LANGUAGE INTERACTIONS
              </h3>
              <h4></h4>
              <p>
                The station encourages healthy interactions in local dialects,
                promoting the use of local languages for communication.
              </p>
              <a href="#" className="link2">
                more info
              </a>
            </div>
          </div>
        </div>

        {/*ADVERT START*/}
        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              {isMobile ? (
                <div className="info_block">
                  <h3
                    className="col2"
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ROSE FM website? <Link to="/contact">click here</Link>{" "}
                    <br />
                  </h3>
                  <h4
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    The station that brings you intriguing programs
                  </h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              ) : (
                <div className="info_block">
                  <h3 className="col2">
                    {" "}
                    DO YOU WANT AN ADVERT SPACE ON <br />
                    ROSE FM website? <Link to="/contact">click here</Link>{" "}
                    <br />
                  </h3>
                  <h4>The station that brings you intriguing programs</h4>
                  <a href="https://live.enyinba.com" target="_blank">
                    <i className="fa fa-play-circle"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {/*ADVERT END*/}

        {/*TOPS NEWS START*/}
        {filteredNews && Object.keys(filteredNews).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>TOP NEWS</h3>
              {filteredNews.map((item, index) => {
                return (
                  <>
                    <Link to={`/news/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Cover}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}
                          <div
                            style={{
                              fontSize: "18px",
                              color: "white",
                              fontStyle: "italic",
                              height: "25px",
                              width: "300px",
                              alignContent: "center",
                              marginRight: "5px",
                              backgroundColor: "#8f2325",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {formatStringToDate(item.CreatedDate)}
                          </div>
                          {/*</div>*/}
                          <div
                            style={{
                              fontSize: "20px",
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>

                          <div
                            style={{
                              marginTop: "10px",
                              fontSize: "17px",
                              color: "black",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.ParagraphOne.split(" ").slice(0, 5).join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*TOPS NEWS END*/}

        <div
          className="bg1 p93"
          style={{
            backgroundColor: "rgba(196,190,190,0.2)",
          }}
        >
          <div className="container_12">
            <div className="grid_12">
              <div className="info_block">
                <h3 className="col2">
                  Captivating moments on ROSE FM <br />
                  <span className="col1">radio station!</span>
                </h3>
                <h4>The station that brings you intriguing programs</h4>
                <a href="https://live.enyinba.com" target="_blank">
                  <i className="fa fa-play-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*PROGRAM START*/}
        <div
          className="p93 bg1"
          style={{
            cursor: "pointer",
          }}
        >
          <div className="container_12">
            <div className="grid_12 center">
              <h3
                className="col1"
                style={{
                  color: "black",
                }}
              >
                Our Programmes
              </h3>
              <div>
                {filteredPrograms && Object.keys(filteredPrograms).length ? (
                  <>
                    {filteredPrograms.map((item, index) => {
                      return (
                        <Link to={`/program/${item.Slug}`}>
                          <div className="grid_4 alpha block1 mb46">
                            <div
                              className="card"
                              style={{
                                width: "300px",
                                height: "280px",
                                backgroundColor: "#d7d3d3",
                                borderRadius: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                border: "1px solid #ddd",
                                marginLeft: "10px",
                                color: "black",
                                fontSize: "18px",
                                fontStyle: "bold",
                                textTransform: "none",
                                margin: "10px",
                                padding: "2px",
                              }}
                            >
                              <div className="grid_4 wrap">
                                <div className="thumb">
                                  <h3
                                    style={{
                                      marginTop: "10px",
                                      fontFamily: "Open Sans, sans-serif",
                                      fontWeight: "300",
                                      textTransform: "uppercase",
                                      display: "block",
                                      fontSize: "35px",
                                      lineHeight: "30px",
                                    }}
                                  >
                                    {item.ProgramName}
                                  </h3>
                                  <h4></h4>
                                  <p>
                                    {item.ShortDescription.split(" ")
                                      .slice(0, 5)
                                      .join(" ")}
                                  </p>
                                  <a
                                    href="#"
                                    className="link2"
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    Read more
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p>There is no available program!</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*PROGRAM END*/}
        {/*PODCAST START*/}
        {filteredPodcast && Object.keys(filteredPodcast).length > 0 && (
          <>
            <div className="container_12 p100">
              <h3>Podcasts</h3>
              {filteredPodcast.map((item, index) => {
                return (
                  <>
                    <Link to={`/podcast/${item.Slug}`}>
                      <div className="grid_4 wrap">
                        <div className="thumb">
                          <img
                            src={item.Image}
                            alt=""
                            width="300"
                            height="287.44"
                          />
                          {/*<div className="circle">*/}

                          {/*</div>*/}
                          <div
                            style={{
                              color: "black",
                              TextTransform: "lowercase",
                              fontWeight: "bold",
                              fontSize: "20px",
                              textAlign: "left", // Center-align text
                            }}
                          >
                            {item.Title}
                          </div>
                          <div
                            style={{
                              color: "black",
                              textAlign: "justify", // Center-align text
                            }}
                          >
                            {item.ShortDescription.split(" ")
                              .slice(0, 5)
                              .join(" ")}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </>
                );
              })}
            </div>
          </>
        )}
        {/*PODCAST ENDS*/}

        {/*INFORMATION MODAL START*/}
        {/*<div*/}
        {/*  id="tpReturnCustomerLoginForm"*/}
        {/*  className="tp-return-customer"*/}
        {/*  style={{*/}
        {/*    position: "relative",*/}
        {/*    zIndex: "9999",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {modalIsOpen && (*/}
        {/*    <div*/}
        {/*      className="tp-modal-overlay"*/}
        {/*      style={{*/}
        {/*        border: "1px solid #D5D8DB",*/}
        {/*        color: "#010F1C",*/}
        {/*        height: "100%",*/}
        {/*        width: "100%",*/}
        {/*        position: "fixed",*/}
        {/*        top: 0,*/}
        {/*        left: 0,*/}
        {/*        backgroundColor: "rgba(0, 0, 0, 0.70)",*/}
        {/*        zIndex: "9999",*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <Modal*/}
        {/*        isOpen={modalIsOpen}*/}
        {/*        onRequestClose={closeVotingSystem}*/}
        {/*        onAttemptClose={onAttemptClose}*/}
        {/*        style={{*/}
        {/*          content: {*/}
        {/*            top: "50%",*/}
        {/*            left: "50%",*/}
        {/*            transform: "translate(-50%, -50%)",*/}
        {/*            height: "1000px",*/}
        {/*            width: "2000px",*/}
        {/*            // width: "746px",*/}
        {/*            // zIndex: "9999",*/}
        {/*          },*/}
        {/*          overlay: {*/}
        {/*            backgroundColor: "rgba(0, 0, 0, 0.5)",*/}
        {/*            zIndex: "9999",*/}
        {/*          },*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            width: "100%",*/}
        {/*            maxWidth: "800px",*/}
        {/*            margin: "0 auto",*/}
        {/*            // width: "500px",*/}
        {/*            // height: "150px",*/}
        {/*            marginTop: "5%",*/}
        {/*            marginLeft: "5%",*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <div*/}
        {/*            style={{*/}
        {/*              width: isMobile ? "340px" : "780px",*/}
        {/*              height: isMobile ? "300px" : "500px",*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            {isMobile ? (*/}
        {/*              <>*/}
        {/*                <img src={vote} width="310px" height="295px" />*/}
        {/*              </>*/}
        {/*            ) : (*/}
        {/*              <>*/}
        {/*                <img src={vote} width="710px" height="480px" />*/}
        {/*              </>*/}
        {/*            )}*/}
        {/*          </div>*/}

        {/*          <div>*/}
        {/*            <button*/}
        {/*              onClick={onCancel}*/}
        {/*              type="submit"*/}
        {/*              className="tp-return-customer-btn tp-checkout-btn"*/}
        {/*              style={{*/}
        {/*                backgroundColor: "#692f2f",*/}
        {/*                // backgroundColor: "#4CAF50",*/}
        {/*                color: "white",*/}
        {/*                border: "none",*/}
        {/*                padding: "10px 20px",*/}
        {/*                borderRadius: "4px",*/}
        {/*                fontSize: "16px",*/}
        {/*                cursor: "pointer",*/}
        {/*                marginRight: "2px",*/}
        {/*                width: "45%",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              Cancel*/}
        {/*            </button>*/}

        {/*            <button*/}
        {/*              onClick={handleVoteNow}*/}
        {/*              type="submit"*/}
        {/*              className="tp-return-customer-btn tp-checkout-btn"*/}
        {/*              style={{*/}
        {/*                backgroundColor: "#8a583c",*/}
        {/*                // backgroundColor: "#4CAF50",*/}
        {/*                color: "white",*/}
        {/*                border: "none",*/}
        {/*                padding: "10px 20px",*/}
        {/*                borderRadius: "4px",*/}
        {/*                fontSize: "16px",*/}
        {/*                cursor: "pointer",*/}
        {/*                marginBottom: "10px",*/}
        {/*                width: "45%",*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              Vote Now!*/}
        {/*            </button>*/}
        {/*          </div>*/}

        {/*          {isSubmitPhoneNumberAndIppAddress && <LoginLoader />}*/}
        {/*        </div>*/}
        {/*      </Modal>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}

        {/*INFORMATION MODAL END*/}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
